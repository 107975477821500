const mainDashboardURL =
  window.location.hostname === "localhost" ? "/" : "/dashboard";

export const websiteRoutes = {
  home: "/",
  login: "/app/login",
  signup: "/app/signup",
  verify: "/app/verify",
  dashboard: "/components/",
  inspiration: "/inspiration/",
  inspirationsHome: "/inspirations",
  componentPageUrl: "/components/",
  preview: "/preview",
  mainDashboard: mainDashboardURL,
};

export const SUPABASE_REDIRECT_URL = `${location.protocol}//${location.host}${websiteRoutes.verify}`;
