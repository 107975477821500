import { WFRoute, navigate } from "@xatom/core";
import { userMiddleware } from "../modules/auth";
import posthog from 'posthog-js';
import { websiteRoutes } from "../config";

export const app = () => {
  // Track page views
  const trackPageView = (path: string) => {
    posthog.capture("$pageview", { path });
  };

  new WFRoute("/(.*)").execute(() => {
    const urlPath = window.location.pathname;
    trackPageView(urlPath);
  });

  new WFRoute(websiteRoutes.mainDashboard).execute(() => {
    import("../modules/inspirationsListing")
      .then(({ initInspirationHandlerPage }) => initInspirationHandlerPage())
      .catch(console.error);

    import("../modules/component")
      .then(({ initComponentsPage }) => initComponentsPage())
      .catch(console.error);
  });

  //will trigger route matches /auth/*
  new WFRoute("/app/(.*)")
    .withMiddleware(userMiddleware, "GUEST", "allow", {
      onError() {
        navigate("/components/");
      },
    })
    .execute(() => {
      //will trigger route matches /auth/sign-up
      new WFRoute("/app/login").execute(() => {
        //lazy loading route
        import("../modules/auth/login")
          .then(({ login }) => login())
          .catch(console.error);
      });
      //will trigger route matches /auth/sign-in
      new WFRoute("/app/signup").execute(() => {
        //lazy loading route

        import("../modules/auth/signUp")
          .then(({ signUp }) => signUp())
          .catch(console.error);
      });
      //will trigger route matches /auth/verify
      new WFRoute("/app/verify").execute(() => {
        //lazy loading route
        import("../modules/auth/verify")
          .then(({ verify }) => verify())
          .catch(console.error);
      });
    });

  new WFRoute("/inspirations").execute(() => {
    import("../modules/inspirationsListing")
      .then(({ initInspirationHandlerPage }) => initInspirationHandlerPage())
      .catch(console.error);
  });

  new WFRoute("/inspiration/(.*)").execute(() => {
    //will trigger route matches /app/task-list
    import("../modules/inspiration")
      .then(({ initInspirationPage }) => initInspirationPage())
      .catch(console.error);
  });

  new WFRoute("/preview").execute((params: { comp_id: string }) => {
    import("../modules/preview")
      .then(({ Preview }) => {
        new Preview(params.comp_id).init();
      })
      .catch(console.error);
  });

  new WFRoute("/components").execute(() => {
    import("../modules/component")
      .then(({ initComponentsPage }) => initComponentsPage())
      .catch(console.error);
  });

  new WFRoute("/components/(.*)").execute(() => {
    import("../modules/component")
      .then(({ initComponentsPage }) => initComponentsPage())
      .catch(console.error);
  });

  new WFRoute("/component/(.*)").execute(() => {
    import("../modules/component")
      .then(({ initComponentsPage }) => initComponentsPage())
      .catch(console.error);
  });

  new WFRoute("/favorites")
    .withMiddleware(userMiddleware, "USER", "allow", {
      onError() {
        navigate(websiteRoutes.login);
      },
    })
    .execute(() => {
      import("../modules/favorites")
        .then(({ Favorites }) => new Favorites())
        .catch(console.error);
    });
};
